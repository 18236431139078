<template>
  <div class="d-flex flex-column px-5 px-md-10 pt-8 create-category-groups">
    <div class="d-flex justify-space-between align-center mb-8">
      <div>
        <v-icon color="primary" size="16" v-text="'arrow_back'" />
        <a @click="$router.push({ name: 'competition-groups' })">
          <span class="ml-1 font-weight-bold">{{ $t('activity.editActivity.navigateBack') }}</span><span>{{ $t('project.sections.groups') }}</span>
        </a>
      </div>
      <v-btn color="primary" class="mr-3" dark depressed v-text="$t('project.competition.groups.createMatches')" />
    </div>
    <v-row>
      <v-col cols="12" class="pa-3">
        <h2 class="mb-6" v-text="cohort.name" />
        <h2 v-text="$t('project.competition.groups.createTheGroupings')" />
        <div class="d-flex my-6">
          <v-icon color="primary" size="24" class="mr-2" v-text="'lightbulb_outline'" />
          {{ $t('project.competition.groups.createTheGroupingsDisclaimer') }}
        </div>
        <v-row>
          <v-col cols="12" sm="5" xl="4" class="py-1 px-3">
            <h3 class="mb-6" v-text="$t('project.competition.groups.howManGroupswantToHave')" />
            <v-text-field outlined dense hide-details="auto" @keypress="validateKeyNumeric($event)" />
          </v-col>
          <v-col cols="12" sm="5" xl="4" class="py-1 px-3">
            <h3 class="mb-6" v-text="$t('project.competition.groups.howManyTeamsWantHaveInEachGroup')" />
            <v-text-field outlined dense hide-details="auto" @keypress="validateKeyNumeric($event)" />
          </v-col>
          <v-col cols="12" sm="2" xl="4" class="py-1 px-3">
            <v-btn color="primary" class="w-100 w-sm-auto mt-sm-13 mr-3" dark depressed v-text="$t('project.competition.groups.createGroups')" />
          </v-col>
        </v-row>
        <div class="d-flex align-center my-6">
          <v-icon color="primary" size="24" class="mr-2" v-text="'done'" />
          <div v-html="$t('project.competition.groups.successCreateGroups', { groups: 4, teams: 8 })" />
          <a class="ml-2" v-text="$t('common.modify')" />
        </div>
        <div class="d-flex flex-column flex-sm-row">
          <div class="mr-4 pt-2 rounded-lg d-flex flex-column create-category-groups__container-elements mb-2 mb-sm-0 w-100 w-sm-25">
            <h3 class="text-center mb-2" v-text="`${$t('club.sections.teams')} (${teams.length})`" />
            <div class="pa-1" style="background: #FFF">
              <v-select
                v-model="selectStatusTeams"
                class="w-100 w-sm-75 float-sm-right"
                :items="statusTeamsItems" append-icon="mdi-chevron-down"
              />
            </div>
            <div class="d-flex flex-column overflow-y-auto">
              <div
                v-for="(team, index) in teams"
                :id="`team-item-${index}`" :key="index"
                :class="[
                  'd-flex align-center pa-2 rounded-lg white create-category-groups__team-item',
                ]"
              >
                <v-icon color="warning" small class="mr-2" v-text="'check_circle_outline'" />
                <span class="ml-2 text-truncate" style="user-select: none;" v-text="team.name" />
              </div>
            </div>
          </div>
          <div class="pa-4 rounded-lg d-flex flex-column create-category-groups__container-elements w-100 w-sm-75">
            <div class="d-flex my-6" style="height: 24px; cursor: pointer">
              <div style="height: 24px">
                <v-icon color="grey50" size="24" class="mr-2" v-text="'delete_outline'" />
              </div>
              <span class="grey50--text" v-text="$t('common.clean')" />
            </div>
            <v-row class="overflow-y-auto">
              <v-col v-for="(group, indexGroup) in groups" :key="indexGroup" cols="12" sm="4">
                <div class="rounded-lg create-category-groups__group-item white">
                  <div class="d-flex pa-2 justify-space-between acqua10 create-category-groups__group-item__header">
                    <div class="d-flex">
                      <div class="mr-2" style="width: 24px">
                        <v-icon
                          color="primary" size="24" class="mr-2"
                          @click="showEditNameGroupDialog = true; groupSelected = group" v-text="'create_outline'"
                        />
                      </div>
                      {{ group.name }}
                    </div>
                    <div v-text="'0 de 5'" />
                  </div>
                  <div class="overflow-y-auto create-category-groups__group-item__content">
                    <div
                      v-for="(team, index) in teams"
                      :id="`team-item-${index}`" :key="index"
                      :class="[
                        'd-flex align-center pa-2 rounded-lg white create-category-groups__team-item',
                      ]"
                    >
                      <v-icon color="warning" small class="mr-2" v-text="'check_circle_outline'" />
                      <span class="ml-2 text-truncate" style="user-select: none;" v-text="team.name" />
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
        <div class="d-flex flex-column flex-sm-row">
          <div class="d-flex my-6 mr-8">
            <v-icon color="warning" size="24" class="mr-2" v-text="'check_circle_outline'" />
            {{ $t('project.competition.groups.filters.teamsAccepted') }}
          </div>
          <div class="d-flex my-6">
            <v-icon color="success" size="24" class="mr-2" v-text="'credit_card'" />
            {{ $t('project.competition.groups.filters.teamsAcceptedAndPaymentFinish') }}
          </div>
        </div>
      </v-col>
    </v-row>
    <edit-name-group-dialog
      v-if="showEditNameGroupDialog"
      :organization-id="organizationId" :project-id="projectId" :cohort-id="cohortId" :group="groupSelected"
      @closeDialog="showEditNameGroupDialog = false"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import formUtils from '@/utils/mixins/formUtils'
import i18n from '@/plugins/i18n'

export default {
  name: 'CreateCohortGroups',
  components: {
    EditNameGroupDialog: () => import('@/modules/competition/groups/EditNameGroupDialog'),
  },
  mixins: [formUtils],
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
    cohortId: { type: String, required: true },
  },
  data: () => ({
    selectStatusTeams: null,
    showEditNameGroupDialog: false,
    groupSelected: null,
  }),
  computed: {
    ...mapGetters({ rawGroups: 'competition/groups' }),
    ...mapGetters('competition', ['cohorts', 'teams']),
    cohort: ({ cohorts, cohortId }) => cohorts.find(c => c.id === cohortId),
    groups: ({ rawGroups, cohortId }) => rawGroups.filter(g => g.cohortId === cohortId),
    statusTeamsItems: () => [
      { text: i18n.t('project.competition.groups.filters.allTeams'), value: null },
      { text: i18n.t('project.competition.groups.filters.teamsAccepted'), value: 'accepted' },
      { text: i18n.t('project.competition.groups.filters.teamsAcceptedAndPaymentFinish'), value: 'acceptedAndPaymentOk' },
    ],
  },
}
</script>
<style lang="scss" scoped>
.create-category-groups__container-elements {
  background: $inactive;
  height: 66vh;
  border: 2px solid rgb(218, 218, 218);
  box-shadow: rgb(245 245 245) 2px 2px 2px 1px;
}

.create-category-groups__team-item {
  box-shadow: rgb(245 245 245) 2px 2px 2px 1px;
  height: 48px;
  margin: 2px;
  cursor: pointer;
  &--has-region-selected {
    &:hover {
      background: $aqua10;
    }
  }
}

.create-category-groups__group-item {
  box-shadow: rgb(245 245 245) 2px 2px 2px 1px;
  height: 200px;
}

.create-category-groups__group-item__content {
  height: 159px;
}

@import '@/styles/scrollbar-mac';
</style>
